const Russian = {
    soroka: 'Сорока',
    language: 'Язык',

    // Общее
    save: 'Сохранить',
    buttonAddProperty: 'Добавить свойство',
    addPropertyTitle: 'Нажмите на нужное свойство, чтобы добавить его',
    placeholderNewCard: 'Новая карточка',
    placeholderEditLink: 'Укажите ссылку...',
    tooltipAllPropertiesAlreadyAdded: 'Все возможные свойства уже добавлены',
    saved: 'Сохранено',
    property: 'Свойство',
    enity: 'Сущность',
    organization: 'Организация',
    owner: 'Владелец',
    loading: 'Загрузка',
    topToolbar: 'Верхняя панель инструментов',

    // Header
    exit: 'Выход',

    // Страница логина
    loginTitle: 'Вход',
    login: 'Войти',
    incorrectLogin:
        'Неправильный логин или пароль. {br} Попробуйте ещё раз, а в крайнем случае напишите администратору: {email}',

    // Список карточек
    notFilled: 'Не заполнена',
    cards: 'Карточки',
    allOrganizations: 'Все организации',
    emptyCardList: 'Нет карточек',

    // Страница создания карточки
    newCardWarningModalText: 'Добавьте любые нужные свойства, заполните карточку и сохраните',
    newCard: 'Новая карточка',
    nameOfCard: 'Название',
    preventDelete: 'Запретить удаление',

    // Страница редактирования карточки
    changeCardWarningModalText: 'Эта карточка заполнена не до конца: в ней есть пустые поля.',

    // Модкала с диалогом
    unsavedChanges: 'Есть изменения',
    saveBeforeExit: 'Сохранить перед выходом?',
    deleteCardConfirm: 'Удалить карточку, все её свойства и файлы?',
    sureCancel: 'Вы уверены, что хотите отменить изменения ?',
    deleteAlert: 'Удалить свойство и данные? ',
    yes: 'Да',
    no: 'Нет',

    ok: 'Ok',
    cancel: 'Отмена',

    // Регистрация
    emailOrPhone: 'Почта или телефон',
    password: 'Пароль',
    repeatPassword: 'Повторите пароль',
    signUp: 'Зарегистрироваться',
    asAdministrator: 'в роли администратора',
    administrator: 'Администратор',
    asEditor: 'в роли редактора',
    editor: 'Редактор',
    asAuthor: 'в роли автора',
    author: 'Автор',
    inviteBrief:
        '<p>Здравствуйте, <b>{name}</b> ({organizationName})! Вас пригласили участвовать в коллективном сборе данных в рамках Ассамблеи петровских музеев в <b>{userRole}</b>.</p><p>Собирать данные будем здесь, в новом приложении “Сорока”, которое создаёт ИТМО. А показывать и рассказывать — на сайте проекта Wunderkammer.</p><p>Нам не терпится увидеть уникальные объекты и истории, о которых знаете только вы, но, прежде всего, необходимы юридические формальности.</p><p>Пожалуйста, внимательно прочтите <agreement>    пользовательское соглашение</agreement>. В нём детально описано, как будут использоваться полученные материалы. Также у нас есть “<simpleAgreement>перевод</simpleAgreement>” соглашения — упрощённое объяснение каждого пункта.</p><p>Нажимая кнопку продолжить, вы соглашаетесь с <agreement>    пользовательским соглашением</agreement> и подтверждаете, что являетесь представителем музея, который имеет право на подобные обязательства</p>',
    continue: 'Продолжить',
    passwordsDoNotMatch: 'Пароли не совпадают',
    registrationByInvitation: 'Регистрация по приглашению',

    // Свойства
    unknownProperty: 'Неизвестное свойство',
    address: 'Адрес',
    legalName: 'Юридическое название',
    artisticText: 'Художественный текст',
    tags: 'Теги',
    sources: 'Источники',
    quote: 'Цитата',
    julianDate: 'Дата',
    geoPoint: 'Гео-точка',
    annotation: 'Аннотация',
    media: 'Медиа',
    bibliography: 'Библиографическое описание',
    copyrightHolder: 'Правообладатель',
    creationPlace: 'Место создания',
    creator: 'Создатель',
    family: 'Родители (семья)',
    format: 'Формат',
    originalText: 'Оригинальный текст',
    participants: 'Участники',
    profession: 'Профессия',
    refutation: 'Опровержение',
    socialNetworks: 'Социальные сети',
    storage: 'Место хранения',
    url: 'URL',
    size: 'Размер',

    help: 'Помощь',
    hideHelp: 'Скрыть помощь',
    delete: 'Удалить',
    rename: 'Переименовать',
    copyLink: 'Скопировать ссылку',

    // Измерения
    cylinderTooltip: 'Цилиндр — колонна, бокал',
    cubeTooltip: 'Три измерения: ящик',
    sphereTooltip: 'Сферический объект',
    planeTooltip: 'Два измерения: картины, фотографии и тд',
    lineTooltip: 'Одно измерение, например — расстояние',
    width: 'Ширина',
    height: 'Высота',
    length: 'Длина',
    diameter: 'Диаметр',
    note: 'Примечание',
    mm: 'мм',
    cm: 'см',
    m: 'м',
    w: 'Ш',
    h: 'В',
    l: 'Д',
    d: 'D',

    noSuchDate: 'Такой даты нет в календаре',
    datesMustBeConsecutive: 'Даты должны быть последовательны',
    calendarGregorian: 'Григорианский календарь',
    calendarGregorianDate: 'Григорианская дата',
    calendarJulian: 'Юлианский (старый стиль) календарь',
    calendarJulianDate: 'Юлианская дата',
    anyString: 'Любая строка',
    calendarStringDate: 'Григорианская дата',
    gregorianDateForStringInfo:
        'Чтобы система могла сортировать и фильтровать карточки, необходимо ввести хотя бы примерную григорианскую дату',
    calendarHelp:
        '<p>Все даты хранятся в формате julian date и могут сравниваться между собой. При смене календаря введённые даты не меняются</p><p> На данный момент поддерживаются юлианский и григорианский календарь.</p><p>Например, можно ввести точные даты “12.04.1698”</p>',
    placeName: 'Название места',
    invalidCoordinates: 'Неправильные координаты',
    coordinatesHelp:
        'Выберите точку на карте или введите координаты в градусах (в виде десятичной дроби через запятую), например, для координат 55°24\'32.1"N 32°15\'32.3"E нужно ввести <b>55.408902,32.258976</b>',

    coverCardFile: 'Обложка',
    mainCardFile: 'Главный',
    uploadFiles: 'Загрузить файлы',
    dragFiles: 'Перетащите файлы сюда',
    orDragFiles: 'или перетащите сюда',
    mediaHelp:
        '<p>Допустимые форматы файлов: jpg, png, mp3</p><div>После загрузки файлов вы сможете выбрать:<ul><li>{eye}<span>Обложку для карточки (среди изображений)</span></li><li>{star}<span>Главный файл (например, это может быть смонтированное интервью среди отрывков, или самое качественное изображение)</span></li></ul></div>',

    templates: 'Шаблоны',
    withoutTemplate: 'Без шаблона',
    choosePropertiesByYourself: 'Все свойства вы выберете сами',

    peterTravelPoint: 'Точка в Посольствах Петра',
    museum: 'Музей',
    book: 'Книга'
}

export default Russian
